import { elementIsVisibleInViewport } from "./misc/functions";

const menuItemsWithChildren = document.querySelectorAll(
  ".menu-item-has-children"
);

const chevronDown = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17 10L12 15" stroke="currentColor" stroke-linecap="square" stroke-linejoin="round"/>
<path d="M7 10L12 15" stroke="currentColor" stroke-linecap="square" stroke-linejoin="round"/>
</svg>
`;

if (menuItemsWithChildren) {
  menuItemsWithChildren.forEach((item) => {
    const link = item.querySelector("a");
    const elementWithIcon = document.createElement("span");
    elementWithIcon.classList.add("menu-item-icon--chevron");
    elementWithIcon.innerHTML = chevronDown;
    link.appendChild(elementWithIcon);

    item.addEventListener("click", (e) => {
      if (item.classList.contains("menu-item-has-children--open")) {
        item.classList.remove("menu-item-has-children--open");
      } else {
        item.classList.add("menu-item-has-children--open");
      }
    });
  });
}
const siteHeader = document.querySelector(".site-header");

if (siteHeader) {
  const checkScrollPosition = () => {
    if (window.scrollY > 0) {
      siteHeader.classList.add("site-header--sticky");
    } else {
      siteHeader.classList.remove("site-header--sticky");
    }
  };
  window.addEventListener("scroll", checkScrollPosition);
  checkScrollPosition();
}

const toggleMenu = document.querySelector(".toggle-menu");
if (toggleMenu) {
  toggleMenu.addEventListener("click", () => {
    if (siteHeader.classList.contains("site-header--open")) {
      siteHeader.classList.remove("site-header--open");
      document.querySelector("body").style.overflow = "";
    } else {
      siteHeader.classList.add("site-header--open");
      document.querySelector("body").style.overflow = "hidden";
    }
  });
}

const cookie = `<svg width="42" height="58" viewBox="0 0 42 58" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_1516_9442)">
<path d="M2.28604 54.5222C1.30861 53.5912 0.649668 52.4872 0.482187 51.1278C0.339417 49.9799 0.49866 48.8731 0.96541 47.8131C1.14387 47.4094 1.37999 47.0414 1.69024 46.7228C2.23936 46.1543 2.91477 45.9593 3.68628 46.0417C4.42484 46.1214 5.11399 46.3658 5.80313 46.6212C6.506 46.8821 7.20612 47.1512 7.90899 47.4066C8.80405 47.7307 9.74029 47.89 10.6656 48.085C11.3574 48.2278 12.0466 48.3843 12.7302 48.5601C13.1503 48.6699 13.5457 48.8594 13.9053 49.1121C14.3968 49.4581 14.7016 49.9332 14.869 50.5017C15.0612 51.1471 15.075 51.8034 14.9816 52.4653C14.8526 53.3715 14.5396 54.2092 14.0179 54.9616C13.5759 55.596 13.002 56.0766 12.3102 56.4227C11.341 56.9115 10.3031 57.0927 9.23236 57.101C7.36811 57.112 5.61643 56.6561 3.98281 55.7608C3.36505 55.423 2.79122 55.0166 2.28329 54.5222H2.28604Z" fill="#ECD1C5"/>
<path d="M16.3521 9.2251C17.9775 9.28002 19.5507 9.61232 21.0964 10.0764C22.439 10.4801 23.7651 10.9333 25.0199 11.5649C26.0495 12.0812 27.0077 12.7046 27.8149 13.534C28.7072 14.454 29.2755 15.5525 29.5748 16.7938C29.9125 18.1972 29.8686 19.5868 29.3716 20.9462C29.152 21.5476 28.8362 22.1024 28.4656 22.6214C27.8094 23.5442 27.1257 24.4505 26.4833 25.3842C25.7804 26.4058 25.3713 27.551 25.2203 28.7813C25.0885 29.8606 24.9842 30.9399 24.8496 32.0192C24.7481 32.8376 24.5971 33.645 24.2895 34.4167C23.9161 35.3504 23.2956 36.0919 22.4857 36.6686C21.3737 37.4623 20.1218 37.8852 18.7627 37.9484C15.9677 38.0747 13.6998 36.9927 11.9317 34.8561C11.1684 33.9361 10.7511 32.8348 10.4985 31.6732C10.3173 30.8383 10.2129 29.9924 10.18 29.1411C10.1333 27.9382 10.1031 26.7354 10.051 25.5325C9.97957 23.8573 9.66657 22.2205 9.2465 20.6002C8.9637 19.5044 8.69738 18.4032 8.49695 17.2909C8.29653 16.1869 8.27456 15.0719 8.62051 13.9789C8.9994 12.7815 9.72423 11.8286 10.6989 11.0514C11.9317 10.0682 13.3484 9.51895 14.9024 9.31572C15.3801 9.25256 15.8688 9.25256 16.3521 9.2251Z" fill="#EDECE8"/>
<path d="M31.233 34.1477C32.0155 34.1752 32.776 34.2823 33.5063 34.5624C34.1323 34.8013 34.6897 35.1583 35.1948 35.595C36.0515 36.3337 36.6884 37.2428 37.254 38.2094C38.0146 39.5029 38.5802 40.8871 39.143 42.2739C39.6097 43.4164 40.0051 44.5836 40.255 45.7947C40.4224 46.6048 40.5075 47.4205 40.4169 48.2471C40.3071 49.2357 39.9365 50.12 39.3132 50.8972C38.5143 51.8914 37.5066 52.5944 36.3288 53.0641C35.6067 53.3524 34.8544 53.5227 34.0746 53.5419C33.1823 53.5639 32.3257 53.3771 31.4938 53.0586C30.3599 52.6246 29.344 51.9848 28.3666 51.2735C27.1585 50.3947 25.9807 49.4802 24.9621 48.3789C23.9434 47.2749 23.1445 46.0418 22.8123 44.5506C22.6503 43.8146 22.6063 43.0731 22.6585 42.3206C22.7409 41.126 23.0456 39.989 23.5783 38.918C24.3333 37.3993 25.4288 36.2019 26.9142 35.3671C27.8614 34.8343 28.8635 34.4663 29.9316 34.2878C30.3599 34.2164 30.7964 34.1944 31.2302 34.1505L31.233 34.1477Z" fill="#FBF3EA"/>
<path d="M30.3325 19.8204C31.0189 17.9721 31.9551 16.1706 32.0897 14.1878C32.4411 9.07971 31.3319 4.58954 25.7885 2.13437C20.0063 -0.427902 12.2034 2.24423 10.4407 8.70072C9.83121 10.9307 9.7104 13.1799 10.5478 15.4318C11.2671 17.3652 12.5438 18.3868 14.8172 18.1452C16.3437 17.9804 17.8758 17.6398 19.0756 16.5249" stroke="black" stroke-width="0.759494" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M30.4697 32.0413C31.4773 34.2383 32.496 36.4298 33.4871 38.6323C34.8681 41.7027 36.4771 44.6906 37.3145 47.9724C38.2315 51.559 36.5786 55.2638 32.6662 55.6702C30.931 55.8515 29.4456 55.3462 28.1415 54.2751C26.5243 52.9514 25.5771 51.1306 24.5365 49.3565C23.3916 47.4094 22.4471 45.3552 21.2391 43.4548C20.8382 42.8259 21.0826 42.3865 21.2912 41.9333C21.7168 41.0051 22.1451 40.1043 22.1067 39.0415C22.0765 38.1929 21.6976 37.6546 20.8602 37.6931C20.0008 37.7343 19.6851 38.5307 19.8031 39.1706C20.0036 40.2389 20.042 41.4473 21.0633 42.1997" stroke="black" stroke-width="0.759494" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12.3485 32.0413C10.8852 34.7875 9.32292 37.4871 7.98307 40.2938C6.67343 43.0373 5.18807 45.7479 4.67739 48.7963C4.21888 51.5371 5.38849 53.6682 7.52456 55.0056C9.55629 56.2771 12.6561 55.5549 14.5423 53.7341C16.4038 51.9353 17.5569 49.6421 18.7979 47.4204C19.5557 46.061 20.2668 44.6769 20.9999 43.3037" stroke="black" stroke-width="0.759494" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M11.9371 20.3695C9.8779 20.0922 7.78851 20.04 5.76776 20.408C2.37422 21.0259 0.784527 23.3136 0.724124 25.9994C0.669213 28.4161 2.15457 30.745 5.21864 31.6046C7.18723 32.1566 9.22994 31.8462 11.2534 31.816C12.5137 31.7968 13.1424 31.3327 13.6091 30.2644C13.8508 29.7069 14.2626 29.1906 14.7019 28.7594C15.1549 28.3145 15.7342 28.0069 16.3328 28.6084C17.0054 29.2812 16.5167 29.8744 16.039 30.2287C15.2538 30.8054 14.4163 31.437 13.3126 31.2173" stroke="black" stroke-width="0.759494" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M29.5087 20.2323C28.6823 19.9192 27.9327 20.2103 27.1722 20.5014C26.6204 20.7101 26.4639 21.2044 26.6588 21.5889C26.8949 22.053 27.3754 22.3332 28.0014 22.0311C28.4928 21.7921 29.009 21.4928 29.2012 21.0452C29.6707 19.9549 30.5603 20.0867 31.4279 20.1004C33.5008 20.1334 35.5765 19.9384 37.6109 20.6277C39.8294 21.3802 41.3861 22.9291 41.5344 25.5903C41.6579 27.823 40.6338 29.5998 38.5856 30.8384C36.3425 32.195 33.9428 31.8325 31.5652 31.8957C30.5877 31.9232 29.8162 31.6458 29.4785 30.539C29.2616 29.8223 28.8964 29.122 28.2759 28.6002C27.8312 28.2267 27.4907 28.2569 27.0212 28.5919C26.4941 28.9682 26.6506 29.63 26.7988 29.8113C27.5072 30.6846 28.3254 31.503 29.6432 31.2174" stroke="black" stroke-width="0.759494" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M16.3301 13.9158C17.1071 14.6491 17.799 15.5169 18.683 16.0826C20.3249 17.1317 22.7849 17.5546 24.3005 15.7064C24.6986 15.223 24.899 14.6848 24.5421 14.2069C24.144 13.6714 23.5125 13.8444 23.065 14.2014C22.0711 14.9978 20.7971 15.429 20.0339 16.5248" stroke="black" stroke-width="0.759494" stroke-linecap="round" stroke-linejoin="round"/>
</g>
<defs>
<clipPath id="clip0_1516_9442">
<rect width="41.3924" height="56.2025" fill="white" transform="translate(0.430176 0.898682)"/>
</clipPath>
</defs>
</svg>
`;

window.addEventListener("load", () => {
  const addIcon = setInterval(() => {
    const cookieBar = document.querySelector(".cky-consent-bar");
    if (cookieBar) {
      const image = document.createElement("span");
      image.innerHTML = cookie;
      cookieBar.appendChild(image);
      clearInterval(addIcon);
    }
  }, 1000);
});

const freeEbookModal = document.querySelector(".free-ebook-modal");
const freeEbookOpenModal = document.querySelector(".free-ebook-open-modal-btn");
const freeEbookCloseModal = document.querySelector(
  ".free-ebook-modal__close-btn"
);
const freeEbookOverlay = document.querySelector(".free-ebook-modal__overlay");

if (freeEbookModal && freeEbookOpenModal) {
  freeEbookOpenModal.addEventListener("click", () => {
    freeEbookModal.classList.add("free-ebook-modal--open");
  });
  freeEbookCloseModal.addEventListener("click", () => {
    freeEbookModal.classList.remove("free-ebook-modal--open");
  });
  freeEbookOverlay.addEventListener("click", () => {
    freeEbookModal.classList.remove("free-ebook-modal--open");
  });
}

document.onreadystatechange = () => {
  if (document.readyState === "complete") {
    const formControls = document.querySelectorAll(".wpcf7-form-control");
    const forms = document.querySelectorAll(".wpcf7-form");
    if (forms && formControls) {
      forms.forEach((el) => {
        el.removeAttribute("novalidate");
      });
      formControls.forEach((el) => {
        if (el.getAttribute("aria-required")) {
        }
        el.setAttribute("required", true);
      });
    }
  }
};

const freeEbook = document.querySelector("#free-ebook");
const ebookFloatingBtn = document.querySelector(".free-ebook-floating-button");

if (freeEbook) {
  window.addEventListener("scroll", () => {
    if (elementIsVisibleInViewport(freeEbook)) {
      ebookFloatingBtn.style.display = "none";
    } else {
      ebookFloatingBtn.style.display = "flex";
    }
  });
}
